@charset "utf-8";
$font-family-base: Ubuntu, Helvetica, Arial, sans-serif;

$spacing-unit: 30px;
@import 'bulma/bulma.sass';
// @import "presets",
//         "bulma",
//         "syntax-highlighting",
//         "font-awesome",
//         "overrides";

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
        
body {
    margin: 0px;
    font-family: 'Ubuntu', sans-serif;
}        
header{
    position: relative;
    margin-left: auto; 
    margin-right: auto; 
    height: 50px;
    .site_title{
        background-color: white;
        font-size: 25px;
        position: absolute;
        width: 100%;
        height: 50px;
        z-index: 20;
        color:white;
        text-shadow: 2px;
        .title{
            text-align: center;
            h1{
                font-weight: bold;
            }
        }
    }
}

.directcontact{
    background-color: green;
    color: white;
    font-weight: bold;
    position: relative;
    text-align: center;
    padding: 5px 0 5px 0;
    a {
        color: white;
        text-decoration: underline;
    }
}

.card{
    height: 100%;
}

.block{
    background-color: lightgray;
    text-align: center;
    font-size: 25px;
    border-radius: 3px;
    font-weight: 500;
    &-why{
        background-color: rgb(44, 139, 44);
        border: 1px solid rgb(20, 63, 20);
        color: white;
        padding: 15px 5px;
    }
    &-benefit{
        background-color: rgb(30, 98, 188);
        border: 1px solid rgb(20, 37, 63);
        color: white;
    }
}

.more-info{
    background-color: rgba(0, 128, 0, 0.3);
}

.button.is-success{
    background-color: rgb(44, 139, 44);
    &:hover{
        background-color: rgb(44, 139, 44);
    }
}

.homeslider{
    background-position: center;
    background-size: cover;
    height: 80vh;
    &__title{
        
    }
    &-first{
        background-image: url('../img/zonnestoel-stoelen-zonder-logo.jpg');
    }
    &-second{
        background-image: url('../img/buiten-zonder-logo.jpg');
    }
    &-third{
        background-image: url('../img/bedrukte-strandstoel-oase-zonder-logo.jpg');
    }
}

@media (min-width: 768px) {
    .columns.equal {
      display: flex;
      flex-wrap: wrap;
    }
  }